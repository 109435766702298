<template>
  <div>
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-header">
          <h4 class="card-title">
            <span>Lista de Agendamentos</span>
          </h4>
        </div>
        <div class="card-body">
          <div>
            <b-row style="gap: 2rem;">

              <b-col md="5">
                <b-form-group label="Número Pedido:">
                  <b-form-input
                    v-model="FiltroTela.NUNOTA"
                    :type="'number'"
                    :min="0"
                  />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group label="Data Inicial:">
                  <b-form-datepicker
                    id="DTENTR"
                    v-model="FiltroTela.DTENTR"
                    locale="pt-BR"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    today-button
                    label-today-button="Selecionar Hoje"
                    reset-button
                    label-reset-button="Limpar"
                    placeholder="Selecione uma Data"
                    :required="true"
                    dropup
                  />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group label="Data Final:">
                  <b-form-datepicker
                    id="DTFINAL"
                    v-model="FiltroTela.DTFINAL"
                    locale="pr-BR"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    today-button
                    label-today-button="Selecionar Hoje"
                    reset-button
                    label-reset-button="Limpar"
                    placeholder="Selecione uma Data"
                    :required="true"
                    dropup
                  />
                </b-form-group>
              </b-col>

            </b-row>
            <b-row style="gap: 2rem;">

              <b-col md="5">
                <b-form-group label="Setor">
                  <b-form-select
                    v-model="FiltroTela.SETOR"
                    :options="optionsSetor"
                    label="DESCR"
                  />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group label="Status Interno">
                  <b-form-select
                    v-model="FiltroTela.STATUSINT"
                    :options="optionsStatusInterno"
                    label="DESCR"
                  />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group label="Status Agendamento">
                  <b-form-select
                    v-model="FiltroTela.STATUSAGEN"
                    :options="optionsStatusAgendamento"
                    label="DESCR"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <div class="DivBotaoLadoLado">
              <b-button
                v-b-toggle="'collapse-filtro'"
                variant="primary"
                type="submit"
                @click="Buscar()"
              ><span>CONSULTAR</span></b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body pb-60">
          <div>
            <b-table
              :items="listaAgendamentos"
              :fields="listaAgendamentosFields"
              responsive="md"
              striped
              sticky-header
            >
              <template #cell(NUNOTA)="row">
                {{ row.value }}
              </template>

              <template #cell(DTENTR)="row">
                {{ row.value ? formatarData(row.value) : '' }}
              </template>

              <template #cell(HORA)="row">
                {{ row.value }}
              </template>

              <template #cell(RAZAOSOCIAL)="row">
                {{ row.value }}
              </template>

              <template #cell(DESCRLOCAL)="row">
                {{ row.value }}
              </template>

              <template #cell(STATUSINT)="row">
                {{ row.value }}
              </template>

              <template #cell(STATUSAGEN)="row">
                {{ row.value }}
              </template>

              <template #cell(ACOES)="row">
                <b-button
                  v-if="row.item.DTENTR"
                  size="sm"
                  variant="outline-primary"
                  @click="editar(row.item)"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="12"
                  />
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import 'bootstrap-icons/font/bootstrap-icons.css'
import {
  BFormGroup,
  BRow,
  BCol,
  BFormDatepicker,
  BButton,
  BTable,
  BFormInput,
  VBToggle, BFormSelect,
} from 'bootstrap-vue'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Spinner from '@/components/Spinner.vue'
import FiltrosPortal from "@/customPortal/FiltrosPortal.vue";

export default {
  components: {
    BFormSelect,
    BFormGroup,
    BFormDatepicker,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormInput,
  },
  directives: { 'b-toggle': VBToggle },
  data() {
    return {
      FiltroTela: {
        TitloTela: 'AgendamentoLista',
        Selecionado: false,
        NUNOTA: '',
        DTENTR: null,
        DTFINAL: null,
        SETOR: null,
        STATUSAGEN: null,
        STATUSINT: null,
        FiltroComplementar: [],
      },
      optionsSetor: [
        {
          value: null,
          text: 'TODOS',
          disabled: false,
        },
      ],
      optionsStatusInterno: [
        {
          value: null,
          text: 'TODOS',
          disabled: false,
        },
        {
          value: 'CONFERIR',
          text: 'CONFERIR',
          disabled: false,
        },
        {
          value: 'CONFERIDO',
          text: 'CONFERIDO',
          disabled: false,
        },
        {
          value: 'DIVERGENTE',
          text: 'DIVERGENTE',
          disabled: false,
        },
      ],

      optionsStatusAgendamento: [
        {
          value: null,
          text: 'TODOS',
          disabled: false,
        },
        {
          value: 'PENDENTE',
          text: 'PENDENTE',
          disabled: false,
        },
        {
          value: 'AGENDADO',
          text: 'AGENDADO',
          disabled: false,
        },
        {
          value: 'ATRASADO',
          text: 'ATRASADO',
          disabled: false,
        },
        {
          value: 'ENTREGUE',
          text: 'ENTREGUE',
          disabled: false,
        },
      ],

      // Adicionar itens para que apareçam os valores no campo, faltando apenas Nome do Produto e Setor/Armazenamento
      listaAgendamentos: [],
      listaAgendamentosFields: [
        { 
          key: 'ACOES',
          label: 'Ações',
          sortable: true,
          tdClass: 'td100',
        },
        {
          key: 'NUNOTA',
          label: 'N° Pedido',
          sortable: true,
          tdClass: 'td100',
        },
        {
          key: 'DTENTR',
          label: 'Data de Entrega',
          sortable: true,
          tdClass: 'td100',
        },
        {
          key: 'HORA',
          label: 'Horário',
          sortable: true,
          tdClass: 'td160',
        },
        {
          key: 'RAZAOSOCIAL',
          label: 'Fornecedor',
          sortable: true,
          tdClass: 'td250',
        },
        {
          key: 'DESCRLOCAL',
          label: 'Setor/Armazenamento',
          sortable: true,
          tdClass: 'td100',
        },
        {
          key: 'STATUSINT',
          label: 'Status Interno',
          sortable: true,
          tdClass: 'td100',
        },
        {
          key: 'STATUSAGEN',
          label: 'Status Agendamento',
          sortable: true,
          tdClass: 'td100',
        },
      ],
    }
  },
  mounted() {
    this.buscarSetor()
    this.descarregarFiltro()
  },
  methods: {
    async Buscar() {
      Spinner.StarLoad()

      let notification = ''

      this.listaAgendamentos = []

      const auxNUNOTA = this.FiltroTela.NUNOTA != null ? this.FiltroTela.NUNOTA : 0
      const auxDTENTR = this.FiltroTela.DTENTR != null ? this.FiltroTela.DTENTR : '1900-01-01'
      const auxDTFINAL = this.FiltroTela.DTFINAL != null ? this.FiltroTela.DTFINAL : '1900-01-01'
      const auxSETOR = this.FiltroTela.SETOR != null ? this.FiltroTela.SETOR : ''
      const auxSTATUSINT = this.FiltroTela.STATUSINT != null ? this.FiltroTela.STATUSINT : ''
      const auxSTATUSAGEN = this.FiltroTela.STATUSAGEN != null ? this.FiltroTela.STATUSAGEN : ''

      const URL = `/AgendamentoEntrega/Buscar?NUNOTA=${auxNUNOTA}&dtentr=${auxDTENTR}&dtfinal=${auxDTFINAL}&local=${auxSETOR}&statusinterno=${auxSTATUSINT}&statusagendamento=${auxSTATUSAGEN}`
      await apiPortalSky.post(URL).then(response => {
        this.resetaFiltros()

        if (response.status === 200) {
          if (response.data.length > 0) {
            this.listaAgendamentos = response.data
          }
        } else if (response.status === 204) {
          notification = ConexaoAPI.MsgFiltroSemRetorno()
          AlertaMsg.FormataMsg(notification)
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if (notification === '') {
        setTimeout(() => {
          Spinner.EndLoad()
        }, 1000)
      }
    },

    editar(item) {
      this.carregarFiltro()
      this.$router.push({ name: 'editarAgendamentoEntrega', params: { nunota: Number(item.NUNOTA), edicaoAdm: true } })
    },

    carregarFiltro() {
      const usuario = this.$session.get('usuario')
      usuario.filtrosTela = FiltrosPortal.AdcionarFiltro(usuario, this.FiltroTela)
      this.$session.set('usuario', usuario)
    },

    descarregarFiltro() {
      const usuario = this.$session.get('usuario')
      const filtro = FiltrosPortal.RetornaFiltro(usuario, this.FiltroTela.TitloTela)
      if (filtro.Selecionado) {
        this.FiltroTela = filtro
        this.Buscar()
      }
    },

    formatarData(data) {
      const dataObj = new Date(data)
      const dia = String(dataObj.getDate()).padStart(2, '0')
      const mes = String(dataObj.getMonth() + 1).padStart(2, '0') // Os meses começam do zero, então adicione 1
      const ano = dataObj.getFullYear()
      return `${dia}/${mes}/${ano}`
    },

    resetaFiltros() {
      if (this.DTENTR === '1900-01-01') this.DTENTR = null
      if (this.DTFINAL === '1900-01-01') this.DTFINAL = null
    },

    buscarSetor() {
      const URL = '/LocalArmazenagem/BuscarLocaisAgendamento'

      apiPortalSky.post(URL)
        .then(response => {
          if (response.status === 200) this.mapearOptionsSetor(response.data)
        }).catch(error => {
          console.error(error)
          AlertaMsg.FormataMsg({
            alert_type: 'error',
            message: 'Erro ao buscar tipos de veículos',
          })
        })
    },

    mapearOptionsSetor(options) {
      for (const opt of options) {
        this.optionsSetor.push({
          disabled: false,
          value: opt.CODLOCAL,
          text: opt.DESCRLOCAL,
        })
      }
    },

  },
}
</script>
